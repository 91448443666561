import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AccountBoxRoundedIconME, Boxes, CategoryRoundedIconME, ContactMailRoundedIconME, HomeRoundedIconME } from './style';
import { Link } from 'react-router-dom';

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Boxes sx={{ width: "100% !important",position:"fixed",bottom:"0px" }}>
      <BottomNavigation
        style={{backgroundColor:"rgba(100,100,100,0.2)",borderRadius:"18px 18px 2px 4px",backdropFilter:"blur(5px)"}}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction LinkComponent={Link} to={'/'}  icon={<HomeRoundedIconME style={{color:"orange"}} />} />
        <BottomNavigationAction LinkComponent={Link} to={'/projects'} icon={<CategoryRoundedIconME style={{color:"orange"}} />} />
        <BottomNavigationAction LinkComponent={Link} to={'/about'} icon={<AccountBoxRoundedIconME style={{color:"orange"}} />} />
        <BottomNavigationAction LinkComponent={Link} to={'/contact'} icon={<ContactMailRoundedIconME style={{color:"orange"}} />} />
      </BottomNavigation>
    </Boxes>
  );
}