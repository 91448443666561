import styled from "styled-components";
import { Input } from 'antd';
import { Button } from "@mui/material";
const { TextArea } = Input;

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    
    padding-top:80px;
    background-color: #202020;
`

export const Wrapper = styled.div`
    width: 100%;
    margin: auto;
    max-width: 1450px;
    height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width:500px){
        align-items: flex-start;
    }
`

export const Box = styled.div`
    width: 450px;
    height: 500px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 20px #333;
    @media (max-width:500px){
        margin: 15px;
        box-shadow: none;
    }
`
export const Title = styled.h1`
    font-family: 'Orbitron';
    color: orange;
    margin-bottom: 10px;
    text-align: start;
`

export const Input1 = styled.input`
    width: 100%;
    height: 50px;
    background: none;
    margin-top: 20px;
    border-radius: 5px;
    outline: none; 
    /* border: 1px solid orange; */
    padding: 7px;
    border: none !important;
    transition: .3s;
    box-shadow: 0px 0px 20px #333;
    color: orange;
    &::placeholder{
        color: orangered;
    }

    &:focus{
        outline: 1px solid orange;
    }

`
export const InputBox = styled(TextArea)`
    width: 100%;
    height: 100px !important;
    border-radius: 5px;
    background: none;
    border: none !important;
    margin-top: 20px;
    transition: .3s;
    color: orange;
    box-shadow: 0px 0px 20px #333;

    &::placeholder{
        color: orangered;
    }
    &:focus{
        outline: 1px solid orange;
    }
`
export const ButtonClick = styled(Button)`
    width: 100% !important;
    color: white;
    font-family: 'Orbitron' !important;
    background-color: orange !important;
    height: 50px !important;
    margin-top: 30px !important;
`