import { useAnimations, useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'
import React, { useRef, useState } from 'react'

const Rock = ({position}) => {
    const { scene, materials, animations } = useGLTF('./models/rock.gltf')
    const group  = useRef();
    const text  = useRef();
    const [ hitSound ] = useState(() => new Audio('./sound/hit.mp3'))
    
    const CollisionEnter = () => {
        hitSound.currentTime = 0
        hitSound.volume = Math.random()
        hitSound.play()
    }
    return (
        <>
            <RigidBody
                ref={text}
                position={position}
                scale={0.2}
                mass={3}
                onWake={CollisionEnter}

            >
                <primitive ref={group} object={scene} />
            </RigidBody>
            
        </>
    )
}

export default Rock