import React from 'react'
import { ButtonVisit, Center, Container, MainTitle, MyTag, ProjectBox, ProjectImage, ProjectImgDiv, TagContainer, Text, Title, Wrapper } from './style'
import { ProjectsData } from '../../data/projects'
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import { DiReact } from 'react-icons/di'
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';

const Projects = () => {


  const navigate = useNavigate();

  const Navigating = (item) => {
    // console.log('item', item)
    navigate(`/detail/${item.title}`, { state: JSON.stringify(item) })
  }

  return (

    <Container>
      <Navbar />
      <MainTitle>Projects</MainTitle>
      <Wrapper>
        {
          ProjectsData.map((item, i) => (
            <ProjectBox key={i}>
              <ProjectImgDiv>
                <ProjectImage src={item.img} />
              </ProjectImgDiv>
              <Title onClick={() => {
                item.id === 1 ? window.open(item.link) : console.log('')
              }}>{item.title}</Title>
              <Text>{item.desc}</Text>
              <TagContainer>
                {item.icons.map((iconka, index) => (
                  <MyTag key={index} color="processing">
                    {iconka.icon} {iconka.name}
                  </MyTag>
                ))}
              </TagContainer>
              <div style={{ marginLeft: 10, marginTop: 10 }}>
                <a
                  className='aaa'
                  style={{ color: "yellow", fontFamily: "cursive", textDecoration: "none" }}
                  target={"_blank"}
                  href={item.link}
                >
                  Visit website
                </a>
              </div>
              <Center>
                <ButtonVisit
                  variant='contained'
                  onClick={() => {
                    Navigating(item)
                  }}
                >
                  View Demo
                </ButtonVisit>

              </Center>
            </ProjectBox>
          ))
        }
      </Wrapper>
    </Container>
  )
}

export default Projects