import { useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'
import React, { useRef } from 'react'

const Boy = ({position}) => {
    const { scene, materials, animations } = useGLTF('./models/boy.gltf')
    const group  = useRef();
    const text  = useRef();


    return (
        <>

            <RigidBody
                ref={text}
                position={position}
                scale={1}
                mass={3}

            >
                <primitive  ref={group} object={scene} />
            </RigidBody>
            
        </>
    )
}

export default Boy