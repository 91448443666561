import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background: #202020;
    
`

export const Wrapper = styled.div`
    width: 100%;
    max-width: 1450px;
    height: 100%;
    
`

export const ContentWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 105px);
`
export const ContentInner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    @media (max-width:666px){
        flex-direction: column;
    }
    margin-top: 100px;
`

export const ImgME = styled.img`
    width: 300px;
    height: 300px;
    border-radius: 100%;
    box-shadow: 0px 0px 40px black;
`

export const Description = styled.div`
    max-width: 700px;
    width: 100%;
    @media (max-width:666px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const Title = styled.h1`
    font-size: 38px;
    font-family: 'Orbitron';
    color: white;
    @media (max-width:666px){
        text-align: center;
    }

`

export const JobTitle = styled.h3`
    font-size: 18px;
    font-weight: 400;
    font-family: 'Lexend';
    color: #BCC7C3;
`

export const ListContainer = styled.ul`
    margin-left: 20px;
    margin-top: 30px;

`
export const ListContainer1 = styled.ul`
    /* margin-left: 20px; */
    margin-top: 0px;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    color: skyblue;
    li{
        margin-left: 20px;
        line-height: 25px;
        font-size: 16px;

    }
`

export const List = styled.li`
    color: white;
    font-family: 'Lexend';
    margin: 4px;
    
    
`
export const Sign = styled.span`
    color: orange;
    font-size:18px;
`
export const Sign2 = styled.span`
    color: yellow;
    margin-left: 5px;
    font-family:'Lexend';
    
`
export const Sign1 = styled.span`
    color: blue;
    font-size:18px;
`

export const Medias = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
`

export const Img = styled.img`
    width: 30px;
    height: 30px;
`

export const Link = styled.a`
    text-decoration: none;
    color: white;
`

export const SummaryContainer = styled.div`
    width: 100%;
    max-width: 1040px;
    margin: auto;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 100px;
`

export const MainHeader = styled.h1`
    font-family: 'Lexend';
    color: orange;
    font-size: 30px;
    margin-top: 40px;
`

export const AboutText = styled.p`
    font-family: 'Lexend';
    color: white;
    margin-top: 15px;
`