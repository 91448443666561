import {
  Text3D,
  useAnimations,
  useGLTF,
  useMatcapTexture,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { RigidBody } from "@react-three/rapier";
import React, { useEffect, useRef, useState } from "react";
import { Car } from "../../Car";
import { useKeyDown } from "./useKeydown";
import { Physics } from "@react-three/cannon";
import { Vector3 } from "three";
const ThreeD = () => {
  const [thirdPerson, setThirdPerson] = useState(true);
  const carRef = useRef();
  const letters = "Vasliddin".split("");
  const job = "JAVASCRIPT DEVELOPER".split("");
  const [matcapTexture] = useMatcapTexture("ED7F04_FCD60E_7E2D04_B73E04", 256);
  const [material, setMaterial] = useState();
  const [metaPressed, setMetaPressed] = useState(false);
  const textRef = useRef();

  const { scene, materials, animations } = useGLTF("./model.gltf");
  const [hitSound] = useState(() => new Audio("./sound/hit.mp3"));
  const group = useRef();
  const main = useAnimations(animations, textRef);

  // console.log('actions', main);

  // useEffect(() => {
  //   actions.Animation.play();
  // }, [mixer]);

  window.addEventListener("keydown", (e) => {
    if (e.key === "Meta") setMetaPressed(true);
    if (e.key === "ArrowUp") {
      textRef.current.applyImpulse({ x: 0, y: 0, z: 1.1 });
    } else if (e.key === "ArrowDown") {
      textRef.current.applyImpulse({ x: 0, y: 0, z: -1.1 });
    } else if (e.key === "ArrowLeft") {
      // textRef.current.applyTorqueImpulse({
      //     x:  0,
      //     y:  0.8,
      //     z:  0
      // })
      textRef.current.applyImpulse({ x: -1.1, y: -0.5, z: 0 });
    } else if (e.key === "ArrowRight") {
      // textRef.current.applyTorqueImpulse({
      //     x:  0,
      //     y:  -0.8,
      //     z:  0
      // })
      textRef.current.applyImpulse({ x: 1.1, y: 0.5, z: 0 });
    }
  });

  const Handle = () => {
    textRef.current.applyImpulse({ x: 0, y: 10, z: 0 });
    textRef.current.applyTorqueImpulse({
      x: Math.random() - 0.8,
      y: Math.random() - 0.8,
      z: Math.random() - 0.8,
    });
  };

  const CollisionEnter = () => {
    console.log("collision!");
    hitSound.currentTime = 0;
    hitSound.volume = Math.random();
    hitSound.play();
  };

  return (
    <>
      <meshMatcapMaterial ref={setMaterial} matcap={matcapTexture} />
      <RigidBody
        onCollisionEnter={CollisionEnter}
        ref={textRef}
        position={[0, 0.4, -1.0]}
        onClick={(e) => Handle(e)}
        scale={0.3}
        mass={3}
      >
        <primitive ref={group} object={scene} />
      </RigidBody>
      {job?.map((item, index) => (
        <RigidBody
          onWake={CollisionEnter}
          position={[-7, -0, -7]}
          key={index}
          restitution={0.1}
        >
          <Text3D
            font="./fonts/helvetiker_regular.typeface.json"
            size={0.7}
            height={0.2}
            material={material}
            curveSegments={12}
            bevelEnabled
            castShadow
            bevelThickness={0.02}
            bevelSize={0.02}
            bevelOffset={0}
            bevelSegments={5}
            position={[index / 1.2, index / 4, 0]}
            rotation-y={0}
            maxWidth={1}
          >
            {item}
            <meshStandardMaterial
              color="orange"
              args={[Math.sin(Math.random() * index), 2, 2]}
            />
          </Text3D>
        </RigidBody>
      ))}
      <group>
        {letters?.map((item, index) => (
          <RigidBody
            onWake={CollisionEnter}
            key={index}
            restitution={0.5}
            position={[-2, 0, 0]}
          >
            <Text3D
              font="./fonts/helvetiker_regular.typeface.json"
              size={0.5}
              height={0.2}
              curveSegments={12}
              bevelEnabled
              bevelThickness={0.02}
              material={material}
              castShadow
              bevelSize={0.02}
              bevelOffset={0}
              bevelSegments={5}
              fontSize={3}
              position={[index / 2, index / 4, 0]}
              rotation-y={0}
              maxWidth={1}
            >
              {item}
            </Text3D>
          </RigidBody>
        ))}
      </group>
    </>
  );
};

export default ThreeD;
