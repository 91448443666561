import axios from "axios";
import React, { useState } from "react";
import {
  AccountBoxRoundedIconME,
  CategoryRoundedIconME,
  ContactMailRoundedIconME,
  HomeRoundedIconME,
  IconButtonMe,
  Navlinks,
} from "../Home/style";
import {
  Box,
  ButtonClick,
  Container,
  Input,
  Input1,
  InputBox,
  Title,
  Wrapper,
} from "./style";
import { message } from "antd";
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const Contact = () => {
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [text, settext] = useState("");
  const [loading, setloading] = useState(false);
  const botToken = "5303225343:AAGFtYZqIgT0TTJ3Sqx1Gbsjf-3d5R9_zuE";
  const GROUPID = -1002040822581;

  async function Send(botinfo) {
    let Data = `📜New Contact (Vasliddin.vercel.app) \n\n\n🧑Name: ${name}\n📩Message: ${text}\n☎️Phone/Email: ${email}`;
    const url = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${GROUPID}&text=${encodeURI(
      Data
    )}`;
    const response = await fetch(url);
    let res = await response.json();
  }

  const OnSubmit = () => {
    setloading(true);
    let data = {
      name: name,
      email: email,
      message: text,
    };
    Send();
    try {
      axios
        .post("https://639a04c7e916a46ec0a6c5d8.mockapi.io/message", data)
        .then((res) => {
          // console.log('res', res)
          setloading(false);
          if (res.status === 201) {
            setname("");
            setemail("");
            settext("");
            messageApi.open({
              type: "success",
              content: "Message sent successfully",
            });
          }
        });
    } catch (err) {}
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Navbar />
      </div>
      {contextHolder}
      <Container>
        <Wrapper>
          <Box>
            <Title>Message</Title>
            <Input1
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Name"
            />
            <Input1
              value={email}
              onChange={(e) => setemail(e.target.value)}
              placeholder="Email or Phone"
            />
            <InputBox
              value={text}
              onChange={(e) => settext(e.target.value)}
              placeholder="Message"
            />
            <ButtonClick variant="contained" onClick={OnSubmit}>
              {loading ? "Submitting..." : "Submit"}
            </ButtonClick>
          </Box>
        </Wrapper>
      </Container>
    </>
  );
};

export default Contact;
