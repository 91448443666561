import { IconButton } from '@mui/material'
import styled from 'styled-components'
import CottageIcon from '@mui/icons-material/Cottage';



export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    border:1px solid red;
`


export const HomeBtn = styled(IconButton)`
    width:50px;
    height: 50px;
    display: ${p=>p.show ? "flex !important" : "none !important"};
    z-index: 999990999999 !important;
    position: absolute !important;
    top: 30px ;
    left: 30px ;
`

export const HomeIcon = styled(CottageIcon)`
    color: orange !important;
`