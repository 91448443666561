import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home/Home';
import About from './pages/About/About'
import NotFound from './components/Notfound/NotFound';
import Projects from './pages/Projects/Projects';
import Detail from './pages/Detail/MainDetail';
import Contact from './pages/Contact/Contact';
import Tohome from './pages/ToHome/Tohome';
import Tab from './pages/Navbar/Tab'
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/detail/:title' element={<Detail />} />
          <Route path='*' element={<NotFound />} />
      </Routes>
      <Tab />
      {/* <Tohome /> */}
    </BrowserRouter>
  )
}

export default App