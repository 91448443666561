import Press from '../assets/press.jpg';
import Hokim from '../assets/hokim1.jpg';
import FCIMG from '../assets/fc.png';
import TME from '../assets/prs.png';
import TG from '../assets/tg.jpg';
import { DiReact,DiJavascript1, DiHtml5 } from 'react-icons/di';
import {SiRedux,SiMaterialui,SiAntdesign,SiJavascript, SiChakraui, SiCss3, SiBootstrap, SiSwiper, SiStyledcomponents} from 'react-icons/si'
import {IoLogoJavascript} from 'react-icons/io'
import {RiWechatPayFill} from 'react-icons/ri'
import { Quickreply } from '@mui/icons-material';



export const ProjectsData = [
    {
        desc:"Shopping website",
        link:'https://samarashop.uz',
        title:'Samarashop ',
        img:'/samara.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Created with React and Three.js",
        link:'https://westercoin.vercel.app',
        title:'Wester Coin',
        img:'/coin.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Three',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Created with React and Three.js",
        link:'https://portfolio-game-one.vercel.app',
        title:'Portfolio',
        img:'/portfolio.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Three',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Created with React and Three.js",
        link:'https://threejs.uz',
        title:' Alita',
        img:'/ai.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Three',
                icon:<DiReact />
            },
            {
                name:'ChatGPT',
                icon:<RiWechatPayFill />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Created with React and Three.js",
        link:'https://suvombori.vercel.app/',
        title:'Pachkamar',
        img:'/pachkamar.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Three',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Created with React and As CHATGpt",
        link:'https://smart-gulchiroy.vercel.app/',
        title:'Gulchiroy',
        img:'/smart.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Three',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        id:1,
        desc:"Created with React Library",
        link:'https://hokimakademiyasi.uz',
        title:'Hokim Academy',
        img:Hokim,
        icons:[
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Redux',
                icon:<SiRedux />
            },
            {
                name:'MUI',
                icon:<SiMaterialui />
            },
            {
                name:'Ant',
                icon:<SiAntdesign />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
        ],
        position: [0, 0, 1.5], rotation: [0, 0, 0], url: Hokim
    },
    {
        id:2,
        desc:"Created with React Library",
        link:'https://pressclub.uz',
        title:'Press Club',
        img:TME,
        icons:[
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Redux',
                icon:<SiRedux />
            },
            {
                name:'MUI',
                icon:<SiMaterialui />
            },
            {
                name:'Chakra',
                icon:<SiChakraui />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            }
        ],
        position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: TME
    },
    {
        id:3,
        desc:"Created with Html and CSS",
        link:'https://cybersport-dc.netlify.app/',
        title:'Cyber Sport',
        img:'https://yi-files.s3.amazonaws.com/products/162000/162535/162536-full.jpg',
        icons:[
            {
                name:'Html5',
                icon:<DiHtml5 />
            },
            {
                name:'Bootstrap',
                icon:<SiBootstrap />
            },
            {
                name:'CSS',
                icon:<SiCss3 />
            },
            {
                name:'JavaScript',
                icon:<SiJavascript />
            }
        ],
        position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: 'https://yi-files.s3.amazonaws.com/products/162000/162535/162536-full.jpg'
    },
    {
        id:4,
        desc:"Created with React Library",
        link:'https://airplane-distance.netlify.app/',
        title:'Flight Control',
        img:'https://aviatorinsider.com/wp-content/uploads/2021/06/Avionics.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Redux',
                icon:<SiRedux />
            },
        ],
        position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: 'https://aviatorinsider.com/wp-content/uploads/2021/06/Avionics.png'
    },
    {
        id:5,
        desc:"Created with React Library",
        link:'http://fcandijon.digital-city.uz',
        title:'Football Club',
        img:FCIMG,
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
]



export const ProjectsData2 = [
    {
        desc:"Created with React Library",
        link:'https://hokimakademiyasi.uz',
        title:'Hokim Academy',
        img:Hokim,
        icons:[
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Redux',
                icon:<SiRedux />
            },
            {
                name:'MUI',
                icon:<SiMaterialui />
            },
            {
                name:'Ant',
                icon:<SiAntdesign />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
        ],
        position: [0, 0, 1.5], rotation: [0, 0, 0], url: Hokim
    },
    {
        desc:"Created with React Library",
        link:'https://pressclub.uz',
        title:'Press Club',
        img:TME,
        icons:[
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Redux',
                icon:<SiRedux />
            },
            {
                name:'MUI',
                icon:<SiMaterialui />
            },
            {
                name:'Chakra',
                icon:<SiChakraui />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            }
        ],
        position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: TME
    },

    {
        desc:"Created with React Library",
        link:'https://airplane-distance.netlify.app/',
        title:'Flight Control',
        img:'https://aviatorinsider.com/wp-content/uploads/2021/06/Avionics.png',
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Redux',
                icon:<SiRedux />
            },
        ],
        position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: 'https://aviatorinsider.com/wp-content/uploads/2021/06/Avionics.png'
    },
    {
        desc:"Created with React Library",
        link:'http://fcandijon.digital-city.uz',
        title:'Football Club',
        img:FCIMG,
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Shopping website",
        link:'https://samarashop.uz',
        title:'Samarashop uz',
        img:FCIMG,
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    {
        desc:"Created with React and Three.js",
        link:'https://westercoin.vercel.app',
        title:'Wester Coin',
        img:FCIMG,
        icons:[
            {
                name:'Swiper',
                icon:<SiSwiper />
            },
            {
                name:'Styled-components',
                icon:<SiStyledcomponents />
            },
            {
                name:'React',
                icon:<DiReact />
            },
            {
                name:'Three',
                icon:<DiReact />
            }
        ],
        position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: FCIMG
    },
    
]