import React, { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Loader, useGLTF, OrbitControls, PerspectiveCamera, Stars, Text3D, Environment } from '@react-three/drei'
import { Physics as NEW, RigidBody } from '@react-three/rapier'
import { Physics } from "@react-three/cannon";

import ThreeD from './ThreeD'
import { Ground } from '../../Ground';
import { Car } from '../../Car';
import { Track } from "../../Track";
import Rock from './Rock';
import Boy from './Boy';
import Navbar from '../Navbar/Navbar';
import { useMediaQuery } from '@mui/material';



export default function Home() {
  const [thirdPerson, setthirdPerson] = useState(false)
  const [cameraPosition, setCameraPosition] = useState([-5, 1.9, 7.21]);
  const [hitSound] = useState(() => new Audio('./sound/night3.mp3'))
  const isMobile = useMediaQuery('(max-width:600px)');


  async function OnPlay(params) {
    if (hitSound.duration > 0 && !hitSound.paused) {

      //Its playing...do your job
      hitSound.pause()

    } else {

      //Not playing...maybe paused, stopped or never played.
      hitSound.currentTime = 0
      hitSound.volume = Math.random()
      hitSound.play()

    }
    console.log()
  }



  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#241B1B"
      }}

    >
      <Suspense fallback={<Loader />}>
        <div className='home_container'>
          <div className="bg" >
            <Loader />
          </div>
          <Navbar />
          <a onClick={OnPlay} id="play-video" class="video-play-button" href="#">
            <span></span>
          </a>
          <Canvas  className='home_canvas' dpr={[1.5, 2]} linear shadows>
            <fog attach="fog" args={['#272730', 16, 30]} />
            <ambientLight intensity={0.75} />
            {/* <PerspectiveCamera makeDefault position={[0, 0, 3]} fov={75}>
          <pointLight intensity={1} position={[-10, -25, -10]} />
          <spotLight castShadow intensity={2.25} angle={0.2} penumbra={1} position={[-25, 20, -15]} shadow-mapSize={[1024, 1024]} shadow-bias={-0.0001} />
        </PerspectiveCamera> */}
            <NEW >
              <Physics gravity={[0, -9.08, 0]}>
                <RigidBody
                  position={[-0.285, -0.0, -0.325]}
                  args={[20, -0.01, 20]}
                  type="fixed" restitution={0} friction={0.7} >
                  <mesh
                    receiveShadow
                    scale={2}
                    position={[-0.285, -0.0, -0.025]}
                  // position={[-0.285, -0.015, -0.325]}
                  >
                    <boxGeometry args={[20, -0.01, 20]} />
                    <meshStandardMaterial opacity={0.1} transparent={true} color="orange" />
                  </mesh>
                </RigidBody>
                <ThreeD />
                <Rock position={[-6.5, 2, -1.0]} />
                <Boy position={[-3.5, 2, 3.0]} />
              </Physics>
            </NEW>
            <Physics>


              <Environment
                files={process.env.PUBLIC_URL + "/textures/envmap.hdr"}
              // background={"both"}
              />
              <PerspectiveCamera makeDefault  position={isMobile ? [-5, 1.9, 13.21] : cameraPosition} fov={40}  />
              {!thirdPerson && (
                <OrbitControls
                  autoRotate
                  autoRotateSpeed={0.3}
                  enablePan={false}
                  target={[-2.64, -0.71, 0.03]}
                  maxPolarAngle={Math.PI / 2.2}
                // minPolarAngle={Math.PI / 2} 
                />
              )}
              <Track />
              <Car thirdPerson={thirdPerson} />
              <Ground />
            </Physics>
            <Stars radius={500} depth={50} count={1000} factor={10} />
          </Canvas>
          <div className="layer" />
        </div>
      </Suspense>
    </div>
  )
}
