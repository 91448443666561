import { Loader } from '@react-three/drei';
import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Detail from './Detail'
import { ButtonME, ButtonME1 } from './style';
import { BackspaceTwoTone, Forward, Replay, Reply } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';


const MainDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { link } = JSON.parse(location.state);
    const isMobile = useMediaQuery('(max-width:600px)');
    const Opening = () => {
        window.open(link)
    }
    return (
        <div 
            style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                height:"100vh",
                backgroundColor:"#241B1B"
            }}
        
        >
            <Suspense
                fallback={<Loader />}
            >
                <ButtonME style={isMobile ? {top:20} : {bottom:20}} startIcon={<Forward />} onClick={Opening}>Visit Website</ButtonME>
                <ButtonME1 style={isMobile ? {top:20} : {bottom:20}} startIcon={<Reply/>} onClick={()=>navigate(-1)}>Back to Projects</ButtonME1>
                <Canvas
                    camera={{
                        fov: 45,
                        near: 0.1,
                        far: 2000,
                        position: [-3, 1.5, 4]
                    }
                    }
                    style={{ width: '100%', height: '100vh' }}

                >

                    <Detail location={location} />
                </Canvas >
            </Suspense>
        </div>
    )
}

export default MainDetail