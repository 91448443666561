import { Button } from "@mui/material";
import { Tag } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    min-height: 100vh;
    background: #202020;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
`

export const MainTitle = styled.h1`
    text-align: center;
    margin-top: 80px;
    font-size: 45px;
    margin-bottom: 20px;
    font-family: 'Orbitron';
    color: white !important;
`

export const Wrapper = styled.div`
    max-width: 1280px;
    width: 100%;
    display: flex;
    margin-top: 20px;
    gap: 20px;
    
    flex-wrap: wrap;
    @media (max-width:1300px){
        padding: 15px;
    }
    @media (max-width:1000px){
        margin-bottom: 50px;

      justify-content: space-around;
    }
`

export const ProjectBox = styled.div`
    width: 300px;
    height: 450px;
    border-radius: 10px;
    /* border: 1px solid white; */
    box-shadow: 0px 0px 20px #0E0F10;
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    .aaa:hover{
        text-decoration: underline !important;
    }
    @media (max-width:500px){
        box-shadow: none;
        width: 97%;
        height: auto;
        overflow: visible;
    }

`
export const ProjectImgDiv = styled.div`
    width: 100%;
    height: 200px;
    overflow: hidden;
    @media (max-width:500px){
        
        height: auto;
    }
`
export const ProjectImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
    object-position: center center;
    &:hover{
        transform: scale(1.2);
    }
`

export const Title = styled.h1`
    color: #E8B059;
    font-family: 'Orbitron';
    padding: 7px;
    &:hover{
        cursor: pointer;
        color: white;
        
    }
`

export const Text = styled.p`
    color: #E8B059;
    font-family: 'Orbitron';
    padding: 7px;

`

export const ButtonVisit = styled(Button)`
    width: 95% !important;
    height: 35px !important;  
    font-family: 'Orbitron' !important;
    background-color: #E8B059 !important;
    @media (max-width:500px){
        width: 100% !important;
        border-radius: 0px !important;
    }
`
export const Center = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    @media (max-width:500px){
        position: static;
        margin-top: 15px;
    }
`
export const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px;
`
export const MyTag = styled(Tag)`
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: ${p=>p.iswide ? '83px' : '75px'} !important;
    color: #E8B059;
    width: auto !important;
    font-size: 11px;
`