import React from "react";
import {
  AccountBoxRoundedIconME,
  CategoryRoundedIconME,
  ContactMailRoundedIconME,
  HomeRoundedIconME,
  IconButtonMe,
  Navlinks,
} from "../Home/style";
import {
  AboutText,
  Container,
  ContentInner,
  ContentWrapper,
  Description,
  Img,
  ImgME,
  JobTitle,
  Link,
  List,
  ListContainer,
  ListContainer1,
  MainHeader,
  Medias,
  Sign,
  Sign1,
  Sign2,
  SummaryContainer,
  Title,
  Wrapper,
} from "./style";
import Instagram from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MouseParticles from "react-mouse-particles";
import { Link as Linkme } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const About = () => {
  function S(item) {
    return (
      <Sign2>
        <Sign>{"["}</Sign>
        <Sign1>{"{"}</Sign1>
        {"..."}
        <Sign1>{"}"}</Sign1>
        <Sign>{"]"}</Sign>
      </Sign2>
    );
  }

  return (
    <>
      <Container>
        <Navbar />
        <Wrapper>
          <ContentWrapper>
            <ContentInner>
              <ImgME src="./me.jpg" alt="Vasliddin Asronbekov" />
              <Description>
                <Title>Vasliddin Asronbekov</Title>
                <JobTitle> Full-Stack Web Developer</JobTitle>
                <ListContainer>
                  <List>
                    {S(" React, JavaScript ")} &nbsp; Frontend Developer
                  </List>
                  <List>
                    {S(" NodeJS, Express.js ")} &nbsp; Backend Developer
                  </List>
                  <List>
                    {S(" React Native, Expo ")} &nbsp; Android Developer
                  </List>
                </ListContainer>
                <Medias>
                  <Link
                    target={"_blank"}
                    href="mailto:vasliddinasronbekov@gmail.com"
                  >
                    <AlternateEmailIcon
                      style={{ color: "white", fontSize: 33 }}
                    />
                  </Link>
                  <Link
                    href="https://t.me/Vasliddin_Asronbekov"
                    target={"_blank"}
                  >
                    <TelegramIcon style={{ color: "white", fontSize: 33 }} />
                  </Link>
                  <Link
                    href="https://github.com/vasliddinasronbekov"
                    target={"_blank"}
                  >
                    <GitHubIcon style={{ color: "white", fontSize: 33 }} />
                  </Link>
                  <Link
                    target={"_blank"}
                    href="https://linkedin.com/in/vasliddin-asronbekov"
                  >
                    <LinkedInIcon style={{ color: "white", fontSize: 33 }} />
                  </Link>
                </Medias>
              </Description>
            </ContentInner>
            <SummaryContainer>
              <MainHeader>Summary</MainHeader>
              <AboutText>
                &nbsp;&nbsp;I'm Software Engineer . I'm experienced with
                Frontend , Backend and Android Development. I participated in
                several projects in several directions that made me developed. A
                person has to choose a profession that he can work even at
                midnight without looking at clock. I think IT is the profession
                that can create a energetic and great environment. :{")"}
              </AboutText>
              <MainHeader>Skills</MainHeader>
              <AboutText>
                <ListContainer1>
                  Frontend Development:
                  <List>JavaScript</List>
                  <List>React</List>
                  <List>Redux</List>
                  <List>Html</List>
                  <List>CSS</List>
                  <List>Scss</List>
                  <List>MUI</List>
                  <List>Antd</List>
                  <List>ChkraUI</List>
                </ListContainer1>
                <ListContainer1>
                  Backend Development:
                  <List>NodeJs</List>
                  <List>Express</List>
                  <List>MySql</List>
                  <List>MongoDB</List>
                  <List>JavaScript</List>
                </ListContainer1>
                <ListContainer1>
                  Android Development:
                  <List>React Native</List>
                  <List>Expo</List>
                  <List>Styled-Components</List>
                </ListContainer1>
                <ListContainer1>
                  Other Skills:
                  <List>Adobe Photoshop</List>
                  <List>3D</List>
                  <List>Algorithm</List>
                  <List>Three.js</List>
                  <List>Linux</List>
                </ListContainer1>
              </AboutText>
              <MainHeader>Languages</MainHeader>
              <AboutText>
                <ListContainer>
                  <List>English (B2)</List>
                  <List>Uzbek (Native)</List>
                  <List>Russian (A1)</List>
                </ListContainer>
              </AboutText>
            </SummaryContainer>
          </ContentWrapper>
        </Wrapper>
      </Container>
      <MouseParticles g={1} num={6} color="random" cull="" level={6} />
    </>
  );
};

export default About;
