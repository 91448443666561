import {ContactShadows, Environment, Float,Html,PresentationControls,useGLTF,Text, OrbitControls } from '@react-three/drei'
import {useMediaQuery} from '@mui/material'

export default function Detail({location}){

    const {id,link,title} = JSON.parse(location?.state)
    const computer = useGLTF('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/macbook/model.gltf')
    const isMobile = useMediaQuery('(max-width:600px)');
    console.log(isMobile)
    return <>

        {/* <Environment preset='city' /> */}
        
        <color args={['#695b5b']} attach='background'  />
        
        <PresentationControls
            global
            rotation={[0.13,0.1,0]}
            polar={[-0.4,0.2]}
            azimuth={[-1,0.75]}
            config={{mass:2,tension:400}}
            snap={{mass:4,tension:400}}
        >
            <Float rotationIntensity={0.4} scale={isMobile ? 0.5 : 1}>
                <rectAreaLight 
                    width={2.5}
                    height={1.65}
                    intensity={65}
                    color={'#8975eb'}
                    rotation={[0.1,Math.PI,0]}
                    position={[0,0.55,-1.15]}
                />
                <primitive 
                    object={computer.scene} 
                    position-y={-1.2}
                >
                    <Html
                        transform
                        distanceFactor={1.17}
                        position={[0,1.56,-1.4]}
                        rotation-x={-0.256}
                    >
                        <iframe className='iframe' src={link} />
                    </Html>
                </primitive>
                <Text 
                    font='/bangers-v20-latin-regular.woff'
                    fontSize={0.5}
                    
                    position={[2,0.75,0.75]}
                    rotation-y={-1.25}
                    maxWidth={2}
                    textAlign='center'
                >
                    {title}
                </Text>
            </Float>
        </PresentationControls>
        
        <ContactShadows 
            position-y={-1.4} 
            opacity={0.4}
            scale={5}
            blur={2.4}
        />
        

    </>
}
