import { Button, useMediaQuery } from "@mui/material";
import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    height: 100vh;
`

export const ButtonME = styled(Button)`
    width: 200px !important;
    height: 40px !important;
    border: none !important;
    position: absolute !important;
    right: 20px !important;
    bottom: 20px !important;
    color: aqua !important;
    z-index: 999999 !important;
`

export const ButtonME1 = styled(Button)`
    width: 200px !important;
    height: 40px !important;
    border: none !important;
    position: absolute !important;
    left: 20px !important;
    bottom: 20px !important;
    color: aqua !important;
    z-index: 999999 !important;
`
