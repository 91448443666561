import React from "react";

import {
  AccountBoxRoundedIconME,
  CategoryRoundedIconME,
  ContactMailRoundedIconME,
  HomeRoundedIconME,
  IconButtonMe,
  Menuicon,
  Navlinks,
} from "./style";
import { Link, useLocation } from "react-router-dom";

import { CommentOutlined, CustomerServiceOutlined } from "@ant-design/icons";
import { Dropdown, FloatButton, Space } from "antd";
import { MenuOutlined } from "@mui/icons-material";

let Navs = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Projects",
    link: "/projects",
  },
  {
    id: 3,
    name: "About",
    link: "/about",
  },
  {
    id: 4,
    name: "Contact",
    link: "/contact",
  },
];

const Navbar = () => {
  const location = useLocation();

  console.log("location", location.pathname);

  return (
    <div className="contents">
      <div className="navbar">
        <div className="logowrapper">
          <img src="./orange.png" alt="" />
          <h1>Vasliddin</h1>
        </div>
        <div className="navs">
          {Navs?.map((item) => (
            <Navlinks isActive={item.link === location.pathname} to={item.link}>
              {item.name}
            </Navlinks>
          ))}

          <IconButtonMe onClick={() => prompt("What is your name ?")}>
            <MenuOutlined />
          </IconButtonMe>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
