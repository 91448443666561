import { Menu } from "@mui/icons-material";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import Box from '@mui/material/Box';

import { IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Boxes = styled(Box)`
    display: none;
    @media (max-width:800px) {
        display: block;
        width: 100% !important;
    }
`

export const Navlinks = styled(NavLink)`
    font-size:18px;
    font-weight: 500;
    text-decoration: none;
    color: ${p=>p.isActive ? "white" : "orange"};
    transition: .3s;
    font-family: 'Orbitron';
    &:hover{
        color: white;
        text-shadow: 0px 0px 10px white;
        transform: scale(1.1);
    }
    @media (max-width:800px) {
        display: none;
    }
`;
export const HomeRoundedIconME = styled(HomeRoundedIcon)`
    color: orange !important;
    font-size:30px !important;
`
export const CategoryRoundedIconME = styled(CategoryRoundedIcon)`
    color: orange !important;
    font-size:30px !important;
`
export const AccountBoxRoundedIconME = styled(AccountBoxRoundedIcon)`
    color: orange !important;
    font-size:30px !important;
`
export const ContactMailRoundedIconME = styled(ContactMailRoundedIcon)`
    color: orange !important;
    font-size:30px !important;
`
export const IconButtonMe = styled(IconButton)`
    display: none !important;
    color: orange !important;
    @media (max-width:800px) {
        display: flex !important;
    }
`